.region-suppliers {
    .region-suppliers-container {
        display: flex;
        flex-wrap: wrap;
        .supplier {
            margin-right: 40px;
            width: 300px;
        }
    }
}
