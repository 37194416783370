.activity-logs {
    .action {
        display: inline-block;
        width: 100px;
        text-align: center;
        text-transform: capitalize;
        font-weight: 700;
        letter-spacing: 0.4px;
        font-size: 12px;
        padding: 8px;
        border-radius: 5px;
        color: #fff;
        background-color: $gray;
        &.update {
            background-color: $flameOrange;
        }
        &.delete {
            background-color: $flame;
        }
        &.create {
            background-color: $balaton;
        }
        &.login {
            background-color: $moss;
        }
    }

    .data-icon {
        margin-left: 10px;
        color: $gray;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
}
