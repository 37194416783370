.cube-details-home {
    .cube-image-tile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        min-height: 300px;

        .cube-image-container {
            img {
                width: 100px;
                height: 100px;
                margin: 5px;
                border-radius: 20px;
                object-fit: cover;
            }
            .first {
                border: 3px solid $flame;
                box-shadow: 0px 0px 7px 1px #000000;
            }
        }
    }

    .create-feature {
        .btn {
            width: 100% !important;
        }
    }
    .feature-title {
        text-align: center;
        margin-bottom: 10px;
    }

    .add-feature-success {
        color: $balaton;
        font-size: 12px;
        margin: 0;
        height: 20px;
    }
    .add-feature-error {
        color: $flameScarlet;
        font-size: 12px;
        margin: 0;
        height: 20px;
    }

    .feature-line {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid $lightgray;

        .feature-image {
            width: 25px;
            height: 25px;
        }

        .feature-name {
            margin-left: 10px;
            margin-bottom: 0;
            color: $gray;
        }

        .highlighted-container {
            justify-content: center;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1;

            .highlighted-text {
                margin-bottom: 0;
                margin-right: 10px;
                font-size: 12px;
                color: $balaton;
            }

            .higlighted-false {
                color: $flameScarlet;
                text-decoration: line-through;
            }

            .form-check-input {
                cursor: pointer;
            }
        }

        .feature-remove-container {
            display: flex;
            justify-content: flex-end;
            width: 50px;

            .feature-remove {
                cursor: pointer;
                color: $flame;
                &:hover {
                    color: $flameHover;
                }
            }
        }
    }
}

.cube-image-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .cube-image-container {
        img {
            margin: 10px;
            width: 150px;
            border-radius: 20px;
            height: 150px;
            object-fit: cover;
        }

        .first {
            border: 3px solid $flame;
            box-shadow: 0px 0px 7px 1px #000000;
        }
        .cubeimage-actions-container {
            display: flex;
            justify-content: flex-end;
            padding-right: 10px;
            align-items: center;
            .cube-remove {
                cursor: pointer;
                color: $flame;
                &:hover {
                    color: $flameHover;
                }
            }

            .cube-highlight {
                cursor: pointer;
                color: $moss;
                &:hover {
                    color: $balaton;
                }
            }
        }
    }
}
