.dashboard-charts {
    .reservation-chart-container {
        min-height: 400px;
    }
    .radar-pie-row {
        .radar,
        .pie {
            height: 400px;
        }
    }
}
