.subpage-navbar {
    display: flex;
    align-items: center;
    margin-left: 32px;

    .tabitem {
        color: $darkgray;
        font-size: 16px;
        padding: 16px;
        height: 36px;
        border-radius: 8px;
        margin: 0px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-decoration: none;

        &.active,
        &:hover {
            color: $flame !important;
            background-color: $light !important;
        }
    }
}
