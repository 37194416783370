.payment-intent-modal {
    .modal-header {
        background-color: $flameOrange;
        color: #fff;
        &.succeeded {
            background-color: $balaton;
        }
    }
    .section {
        .section-title {
            // color: $gray;
            border-bottom: 1px solid $lightgray;
        }
        margin-bottom: 30px;

        .payment-intent-row {
            .payment-intent-col {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                .payment-intent-label {
                    color: $gray;
                }
            }
        }
    }
}
