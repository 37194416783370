.login-screen {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $flame;

    .login-container {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        min-width: 80%;
        height: 582px;
        padding: 32px;

        @media screen and (min-width: 425px) {
            width: 400px;
            min-width: 0;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            height: 160px;
            margin: 24px;
        }
        .form-container {
            p {
                font-family: Proxima Nova;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.3px;

                color: $gray;
            }
            .form-group {
                position: relative;
                .input-error {
                    text-align: start;
                    color: $flame;
                }
                .eye-icon {
                    position: absolute;
                    right: 20px;
                    top: 36px;
                    color: #969696;
                    cursor: pointer;
                }
            }
            .login-button {
                width: 100%;
                height: 48px;
            }

            .change-view-container {
                margin-top: 20px;
                cursor: pointer;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}
