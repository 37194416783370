.verification-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $flame;

    .verification-container {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        min-width: 80%;
        height: 582px;
        padding: 32px;
        font-family: Proxima Nova;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.3px;

        color: $gray;

        @media screen and (min-width: 425px) {
            width: 400px;
            min-width: 0;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            height: 160px;
            margin: 24px;
        }

        p {
            font-size: 18px;
        }

        .login-link {
            margin-top: 40px;
            .login-icon-container {
                margin-left: 5px;
            }
        }
    }
}
