.reservation-orders {
    .section-header {
        display: flex;
        align-items: flex-end;
        .title,
        p {
            margin-bottom: 0px;
            margin-left: 10px;
        }
    }
    .order-infos {
        display: flex;
        .data-container {
            font-weight: 600;
            margin: 10px 0;
            display: flex;
            justify-content: center;
            align-content: space-between;
            // margin: 0 30px;

            .status-container {
                min-width: 200px;
                // width: 100%;
                // max-width: 300px;
                .status-info {
                    display: flex;
                    .status-chevron-container {
                        cursor: pointer;
                        &:hover {
                            color: $gray;
                        }
                    }
                }
                .status {
                    background: #00a66e;
                    color: #fff;
                    border-radius: 4px;
                    width: 109px;
                    height: 24px;
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    &.open {
                        background-color: $flameScarlet;
                    }
                    &.accepted {
                        background-color: $moss;
                    }
                    &.complete {
                        background-color: $balaton;
                    }
                }

                .select-status {
                    width: 200px;
                }
            }
        }
        .action-container {
            display: flex;

            .action-button {
                box-shadow: none;
                color: $darkgray;
                font-size: 14px;
                font-weight: normal;
                padding: 16px;
                height: 36px;
                border-radius: 8px;
                margin: 0px 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $lightgray !important;
                cursor: pointer;

                &.active,
                &:hover {
                    color: $flame !important;
                    background-color: $light !important;
                }

                &.delete {
                    background-color: $flameScarlet !important;
                    .action-name {
                        color: $light;
                    }

                    &:hover {
                        background-color: $flame !important;
                    }
                }
            }
        }
    }

    .order-images {
        .images-title {
            display: flex;
            .add-icon {
                cursor: pointer;
                &:hover {
                    opacity: 0.7;
                }
                border: 1px solid $lightgray;
                border-radius: 5%;
                margin-left: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
            }
        }

        .images {
            display: flex;
            flex-wrap: wrap;

            .order-img {
                width: 100px;
                height: 100px;
                object-fit: contain;
                margin-right: 5px;
                margin-bottom: 5px;
            }
        }
    }

    .add-order-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $flame;

        .add-order-icon-container {
            cursor: pointer;
            &:hover {
                background-color: $flameHover;
            }
        }
    }

    @media screen and (max-width: 576px) {
        .order-infos {
            .data-container {
                width: 100%;
                .status-container {
                    width: 100%;
                }
            }
            .action-container {
                width: 100%;
                justify-content: center;
            }
        }
    }
}
