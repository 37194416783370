.photo-uploader {
    .body {
        height: 400px;

        .crop-container {
            position: "relative";
            width: "100%";
            height: 500px;
            background: "#333";
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;

            .reactEasyCrop_CropArea {
                border-radius: 50%;
            }
        }
    }

    .modal-footer {
        justify-content: center;
    }
}
