.warehouse {
    .warehouse-infos {
        background-color: $moss;
        color: #fff;
        padding: 20px;
        border-radius: 20px;
        max-width: 500px;
        margin: 30px auto 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .add-icon {
            border: 1px solid #fff;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}
