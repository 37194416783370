.product-details {
    .product-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        font-style: italic;
    }

    .product-image-tile {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 300px;

        .product-image-container {
            img {
                width: 100%;
                border-radius: 20px;
                object-fit: cover;
            }
        }
    }
}
