.reservation-maininfos {
    font-weight: 600;
    .status-container {
        .status-info {
            display: flex;
            .status-chevron-container {
                cursor: pointer;
                &:hover {
                    color: $gray;
                }
            }
        }
        .status {
            background: #00a66e;
            color: #fff;
            border-radius: 4px;
            width: 109px;
            height: 24px;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            &.open, &.rejected {
                background-color: $flameScarlet;
            }
            &.confirmed {
                background-color: $flameOrange;
            }
            &.ready {
                background-color: $moss;
            }
            &.complete {
                background-color: $balaton;
            }
        }

        .select-status {
            width: 200px;
        }
    }
}
