.region-info {
    font-weight: 600;

    .region-image-container {
        width: 100%;
        height: 200px;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 20px;
        .region-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
