.loader {
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-border {
        margin-top: 100px;
        color: $flame;
    }
}
