.supplier-update {
    .edit-container {
        margin-top: 20px;

        .avatar-container {
            flex-direction: column;
            display: flex;
            align-items: center;

            .user-picture {
                width: 100px;
                border-radius: 50%;
                margin: 20px;
            }
        }
    }
}
