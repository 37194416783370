.region-form {
    .preview-image {
        width: 100%;
        border-radius: 8px;
        object-fit: contain;
    }

    .map {
        min-height: 300px !important;
    }
}
