.not-found-page {
    display: flex;
    justify-content: center;
    align-items: center;

    .not-found-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon {
            color: $flameScarlet;
            height: 100px;
            width: 100px;
        }

        .error-code {
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 0;
            color: $flameScarlet;
        }

        .not-found-text {
            color: $flameScarlet;
        }
    }
}
