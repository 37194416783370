.map {
    button {
        box-shadow: none;
    }

    .searchbox {
        box-sizing: border-box;
        width: 240px;
        height: 44px;
        padding: 20px 12px;
        border-radius: 3px;
        font-size: 14px;
        outline: none;
        position: absolute;
        bottom: 20px;
        left: 10px;

        margin-top: 10px;
        border: 1px solid $lightgray;

        &::placeholder {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
        }
    }
}
