.product-order-screen {
    .empty-data {
        padding: 0px 40px;
        color: $gray;
    }
    .filter-section {
        padding: 24px 40px;
        width: 100%;
        display: flex;
        align-items: center;

        p {
            min-width: fit-content;
            margin-right: 10px;
            color: $gray;
        }

        input {
            margin-bottom: 20px;
            background: #f8f8f8;
            border: 1px solid #e5e5e5;
            border-radius: 8px;
            color: $gray;
        }
    }

    .order {
        margin-bottom: 40px;

        .reservation-data {
            padding: 24px 40px;
            display: flex;

            .created-at {
                color: $flame;
            }

            .data-line {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-right: 40px;
                p {
                    margin: 4px 2px 0;
                }

                svg {
                    color: $gray;
                }
            }
        }
    }
}
