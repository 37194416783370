.operator-info {
    font-weight: 600;

    .avatar {
        margin: 20px 0;
        .user-picture {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    .invitation-info {
        margin: 5px 0;
        padding: 5px;
        border-radius: 8px;
        color: #fff;
        // text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;

        p {
            margin: 0;
        }

        &.verified-true {
            background-color: $balaton;
        }

        &.verified-false {
            background-color: $flameOrange;
        }

        .inivte-again {
            text-decoration: underline;
            margin-left: 5px;
            color: $flameScarlet;
            cursor: pointer;
            width: fit-content;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}
