.product-table {
    .quantity-container {
        display: flex;
        align-items: center;
        .quantity-input-container {
            display: flex;
            input {
                max-width: 50px;
                margin: 0;
                text-align: center;
            }
            .quantity-set {
                cursor: pointer;
                &:hover {
                    opacity: 0.7;
                }
            }
        }

        .action {
            width: 20px;
            height: 20px;
            border: 1px solid $lightgray;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
            &.close {
                background-color: $flameOrange;
                margin: 0 5px 0 2px;
            }
            &.add {
                background-color: $moss;
            }
            &.edit {
                margin-left: 10px;
                color: $flameOrange;
            }
        }
    }
}
