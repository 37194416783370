.reservation-details-page {
    .doughnot-chart-container {
        display: flex;
        flex-direction: column;
    }

    .home-assistant-section {
        background-color: $moss;
        color: $lightgray;
        justify-content: space-around;
        align-items: center;
        text-align: center;

        .home-assistant-boxes {
            justify-content: center;
            .box {
                border: 1px solid $lightgray;
                border-radius: 20px;
                margin: 10px;
                padding: 10px;
                background-color: $lightgray;
                color: $gray;

                .box-icon {
                    margin: 10px;
                }
            }
        }
    }
}
