.dashboard-page {
    .card-section {
        .card-header {
            height: 150px;
            background-color: #fff;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .card-count {
                h4 {
                    font-size: 40px;
                    font-weight: bold;
                }
            }

            .icon {
                border-radius: 50%;
                padding: 7px;
                background-color: $flame;
                color: #fff;
            }
        }

        .card-title {
            text-align: center;
        }
    }
}
