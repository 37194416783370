//Global styles
@import "./global.scss";

// components

@import "./components/sidebar.component";
@import "./components/navbar-top.component.scss";
@import "./components/table.component";
@import "./components/operator-info.component";
@import "./components/cube-info.component";
@import "./components/region-info.component";
@import "./components/reservation-maininfos.component";
@import "./components/activity-logs.component";
@import "./components/reservation-orders.component";
@import "./components/order-unit-action.component";
@import "./components/product-infos.component.scss";
@import "./components/product-details.component.scss";
@import "./components/dashboard-charts.component";
@import "./components/region-cubes.component";
@import "./components/user-update.component";
@import "./components/operator-update.component";
@import "./components/supplier-update.component";
@import "./components/loader.component";
@import "./components/profil-image-uploader";
@import "./components/region-suppliers.component";
@import "./components/cube-details-home.component.scss";
@import "./components/map.component.scss";
@import "./components/subpage-navbar.component.scss";
@import "./components/create-category.component";
@import "./components/region-form.component";
@import "./components/cube-sensors-component";
@import "./components/cubes-table.component";
@import "./components/cube-shop.component";
@import "./components/payment-intent-modal.component";
@import "./components/product-table.component";
@import "./components/warehouse.component.scss";

// screens
@import "./pages/login.page";
@import "./pages/dashboard.page";
@import "./pages/admin.layout";
@import "./pages/verification.page";
@import "./pages/regions.page";
@import "./pages/reservation-details.page";
@import "./pages/not-found.page";
@import "./pages/product-order.page";

//Bootstrap
@import "~bootstrap/scss/bootstrap";
