.create-category {
    .category-success {
        color: $balaton;
    }
    .category-created-name {
        font-weight: bold;
        font-size: 18px;
    }

    .category-created-description {
        color: $gray;
    }

    .preview-image {
        width: 100%;
        object-fit: contain;
        height: 300px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .submit-button {
        width: 100%;
    }

    .delete-dropdown{
        margin-top: 20px;
    }
}
